import React, { useState } from "react"
import {createBrowserHistory} from 'history'
import axios from 'axios';
import Loader from "react-loader-spinner";
import style from './AuthCalendar.module.css';
import { styleNames } from "../../utils/style-names";
const sn = styleNames(style);

export default function AuthCalendar  ()  {
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(false)
  const CLIENT_ID =  process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY =  process.env.REACT_APP_API_KEY;
  const EMAIL_ACCESS ="testapi@testapi-315606.iam.gserviceaccount.com";
  const search = createBrowserHistory().location.search; // * текущий параметр
  const companyId = search.replace('?companyId=', '');
  const currentTimeZoneOffsetInHours = new Date().getTimezoneOffset() / 60;

  function authenticate() {
    return window.gapi.auth2.getAuthInstance()
        .signIn({scope: "https://www.googleapis.com/auth/calendar"})
        .then((googleUser)=>  {   
        // ? метод возвращает объект пользователя где есть все необходимые нам поля
        const profile = googleUser.getBasicProfile()
        // токен
        const id_token = googleUser.getAuthResponse().id_token
        const authResponse = googleUser.getAuthResponse()
        localStorage.setItem('token', id_token); // * запись токена в localStorage

        const userData = {id: profile.getId(),
          fullName: profile.getName(),
          givenName: profile.getGivenName(),
          familyName: profile.getFamilyName(),
          imageUrl: profile.getImageUrl(),
          email: profile.getEmail(),
          id_token: id_token,
          dataAuth: authResponse
      }
       return userData
      })
  }

  function loadClient(userData) {
    window.gapi.client.setApiKey(API_KEY);
    window.gapi.client.load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
        .then(function() { 
          postCalendar(userData) 
      }).catch(error=>{
        setAuth(false)
        setLoading(false)
        console.error("Error loading GAPI client for API", error);});
  }

  function postCalendar(userData) {
    setLoading(true)
    return window.gapi.client.calendar.calendars.insert({
      "resource": {
        "summary": "ChatBot-Записи",
        "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone, // *определяем таймзону браузера
        "timeZoneOffset": currentTimeZoneOffsetInHours
      }
    })
      .then(responsePostCalendar => {
     return postCalendarAcl(responsePostCalendar.result.id) // * передаем id созданного календаря
      .then(resolve => {
       console.log(`postCalendarAcl response: ${JSON.stringify(resolve)}`)
       return responsePostCalendar}, // * в случае успеха возвращаем и прокидаем ответ postCalendar, а не postCalendarAcl
       reject => console.log(reject))   
    })
      .then(function(response) {
          setLoading(false)
          axios.post(`https://api.chatbullet.com/api/v1/send/7c7077ca5231fd6ad758b9d49a2a1eeb/4816725`, {user: userData,
          calendar: response.result, companyId}).then(resolve =>  {
            setLoading(false)
            setAuth(true)}, 
            reject => console.log(reject))
              }).catch(error => {
                setLoading(false)
                setAuth(false)
                console.error('error postCalendar: ', error)});
  }

  function postCalendarAcl(calendarId) {
    console.log(`postCalendarAcl with ID: ${calendarId}`)
    return window.gapi.client.calendar.acl.insert({
      "calendarId": calendarId,
      "resource": {
        "role": "owner",
        "scope": {
          "type": "user",
          "value": EMAIL_ACCESS
        }
      }
    })}

  window.gapi.load("client:auth2", function() {
    window.gapi.auth2.init({client_id: CLIENT_ID}).then(
              ()=>{console.log('init ok')
              renderButton()
            },()=>{console.log('init err')
            });
  });

const onAuthAndPostCalendar = ()=>authenticate().then(loadClient).catch(err=>{
  setAuth(false)
  setLoading(false)
  console.error("Error signing in", err);})
 
  function renderButton() {
    window.gapi.signin2.render('myGoogleButton', {
       'scope': 'profile email',
       'width': 240,
       'height': 40,
       'longtitle': true,
       'theme': 'dark',
       'onsuccess': (googleUser) => {console.log('Logged in as: ' + googleUser.getBasicProfile().getName())},
       'onfailure': (error) => {console.log(error)}
   });
}
    return(<div className={style.container}>
        <div className={sn('row', {'hidden': loading})}>
        <div id='myGoogleButton'  onClick={()=>onAuthAndPostCalendar()}></div>
</div>
       {loading && <Loader
        type="TailSpin"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={6000} //6 secs
      />}
        {auth && <div className="jumbotron text-center">
        <h1 className="display-3">Календарь успешно создан!</h1>
  <p className="lead">Пожалуйста, вернитесь в чат-бот, чтобы продолжить настройку.</p>
  <hr />
  <p>
    Что-то не получается? <a href="https://t.me/joinchat/lTwuSa8Ysa1kYThi">Напишите нам</a>
  </p>
  <p className="lead">
    <a className="btn btn-primary btn-sm" href="http://t.me/beautyadminpro_bot">Продолжить настройку</a>
  </p>
</div>}
              </div>)
}
