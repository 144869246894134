import AuthCalendar from './components/AuthCalendar/AuthCalendar';
import style from './App.module.css';

function App() {
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight() // ? динамически определяем высоту экрана и запишем её в css переменную

  return (
    <div className={style.container}>
      <AuthCalendar/>
<a className={style.policy} href="https://chatbullet.com/docs/privacy-policy-beauty-bot" target="blank">Политика конфиденциальности</a>
    </div>
  );
}

export default App;
